import './product-teaser-list.scss';
import Swiper from 'swiper';
import { A11y, Navigation, Keyboard } from 'swiper/modules';

class ProductTeaserList {
    constructor (element, options) {
        // Default options
        const defaults = {
            initAttr: 'data-product-teaser-list',
            styleAttr: 'data-product-teaser-list-style',
            anchorAttr: 'data-product-teaser-list-anchor',
            slideTargetAttr: 'data-product-teaser-list-target',
            sliderValue: 'slider',
            slideValue: 'slide',
            controlsValue: 'controls'
        };

        // Settings
        this.settings = Object.assign({}, defaults, options);
        this.breakpoint = 768; // Mobile breakpoint
        this.slider = null; // Swiper instance
        this.sliderRequired = null; // Determines if the slider initialization is necessary
        this.sliderActive = false; // Tracks if the slider is currently active

        // Elements
        this.$productTeaserList = element;
        this.$slider = this.$productTeaserList.querySelector(`[${this.settings.initAttr}="${this.settings.sliderValue}"]`);
        this.$$slides = this.$productTeaserList.querySelectorAll(`[${this.settings.initAttr}="${this.settings.slideValue}"]`);
        this.$$anchors = this.$productTeaserList.querySelectorAll(`[${this.settings.anchorAttr}]`);
        this.$controls = this.$productTeaserList.querySelector(`[${this.settings.initAttr}="${this.settings.controlsValue}"]`);
        this.$indicator = null; // Element to show the current slide and total slides
        this.$indicatorCurrentSlide = null; // Element showing the current slide number
        this.$indicatorTotalSlides = null; // Element showing the total number of slides

        // Additional options
        this.style = this.$productTeaserList.getAttribute(this.settings.styleAttr);
    }

    initialize () {
        if (this.style !== null && this.$controls && this.$slider && this.$$slides.length > 0) {
            this.checkSize();

            // Adding resize listener to recheck slider requirements on window resize
            window.resizeHandler.customFunctions.push(() => {
                this.checkSize();
            });

            if (this.$$anchors.length > 1) {
                this.initSlideAnchor();
            }
        }
    }

    initSlideAnchor () {
        this.$$anchors.forEach($anchor => {
            $anchor.addEventListener('click', (e) => {
                e.preventDefault();
                const targetGroup = $anchor.getAttribute(this.settings.anchorAttr);
                const targetSlideIndex = [...this.$$slides].findIndex($slide =>
                    $slide.getAttribute(this.settings.slideTargetAttr) === targetGroup
                );

                if (targetSlideIndex !== -1) {
                    this.slider.slideTo(targetSlideIndex);

                    this.$$anchors.forEach($otherAnchor =>
                        $otherAnchor.classList.toggle(
                            'product-teaser-list__anchor-nav-trigger--active',
                            $otherAnchor === $anchor
                        )
                    );
                }
            });
        });
    }

    checkSize () {
        // Determines if the slider is required based on the window width and the number of slides
        if (this.style === 'style-1') {
            this.sliderRequired = this.$$slides.length > 3;
        } else {
            this.sliderRequired = this.$$slides.length > 2;

            if (window.innerWidth >= 1024) {
                this.sliderRequired = this.$$slides.length > 3;
            }
        }

        // Building the slide indicator if necessary
        if (this.sliderRequired && this.$indicator === null && window.innerWidth < this.breakpoint) {
            this.buildIndicator();
        }

        // Initializes or destroys the slider based on the current requirements
        if (this.sliderRequired && !this.sliderActive) {
            this.initSlider();
        } else if (!this.sliderRequired && this.sliderActive) {
            this.destroySlider();
        }
    }

    // Initializes the slider with specific settings
    initSlider () {
        this.slider = new Swiper(this.$slider, {
            modules: [A11y, Navigation, Keyboard],
            a11y: true,
            lazyPreloadPrevNext: true,
            keyboard: true,
            direction: 'horizontal',
            slidesPerView: this.style === 'style-1' ? 'auto' : 1,
            slidesPerGroup: this.style === 'style-1' ? 2 : 1,
            speed: 500,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            on: {
                slideChange: () => {
                    // Updating the custom pagination on slide change
                    this.updateCustomPagination();

                    this.$$anchors.forEach($anchor => {
                        $anchor.classList.remove('product-teaser-list__anchor-nav-trigger--active');
                    });
                }
            },
            breakpoints: {
                768: {
                    slidesPerGroup: 1,
                    slidesPerView: 'auto'
                }
            }
        });

        // Initial update for the custom pagination
        this.updateCustomPagination();

        this.sliderActive = true;
    }

    // Creates the indicator element and appends it to the DOM
    buildIndicator () {
        this.$indicator = document.createElement('div');
        this.$indicator.classList.add('product-teaser-list__indicator');
        this.$indicatorCurrentSlide = document.createElement('span');
        this.$indicatorTotalSlides = document.createElement('span');

        this.$indicator.appendChild(this.$indicatorCurrentSlide);
        this.$indicator.appendChild(this.$indicatorTotalSlides);
        this.$slider.insertAdjacentElement('afterend', this.$indicator);

        // Initial update for the indicator
        this.updateCustomPagination();
    }

    // Destroys the Swiper instance if it's no longer required
    destroySlider () {
        if (typeof this.slider !== 'undefined' && this.slider !== null) {
            this.slider.destroy();
            this.sliderActive = false;
        }
    }

    // Updates the custom pagination with the current slide and the total number of slides
    updateCustomPagination () {
        if (this.$indicator !== null && this.slider !== null) {
            this.$indicatorCurrentSlide.textContent = this.style === 'style-1' ? this.slider.activeIndex + 3 : this.slider.activeIndex + 1; // Current slide number

            if (this.$indicatorTotalSlides.textContent === '') {
                this.$indicatorTotalSlides.textContent = this.slider.slides.length; // Total slides
            }
        }
    }
}

export { ProductTeaserList };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$roots = $context.querySelectorAll('[data-product-teaser-list="root"]');
        if ($$roots.length > 0) {
            $$roots.forEach($root => {
                const $rootAPI = new ProductTeaserList($root);
                $rootAPI.initialize();
            });
        }
    }
});
